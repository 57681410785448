import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import img1 from './images/img1.png';
import img2 from './images/img2.png';
import QualityLogo from './images/quality.svg';
import MessageLogo from './images/Frame 4.png';
import GlobeLogo from './images/inmersion.png';

import { Button, NamedLink } from '../../components';

import css from './SectionStudent.module.css';
import { AiOutlineHome } from 'react-icons/ai';
const EachStep = ({ image, logo, title, content }) => {
  if (image)
    return (
      <div className={css.eachStepImage}>
        {' '}
        <img src={image}></img>{' '}
      </div>
    );
  return (
    <div className={css.eachStep}>
      {logo}
      <div>
        <h3 className={css.subTitle}>{title}</h3>
        <p className={css.text}>{content}</p>
      </div>
    </div>
  );
};
const SectionStudent = props => {
  const { rootClassName, intl, className, isAuth } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <div className={css.border}></div>
        <FormattedMessage id="SectionStudent.title" />
      </div>
      <div className={css.stepsContainer}>
        <EachStep className={css.eachStep} image={img1} />
        <EachStep
          className={css.eachStep}
          logo={<AiOutlineHome className={css.logo} />}
          title={intl.formatMessage({ id: 'SectionStudent.SectionStudent.s2T' })}
          content={
            <FormattedMessage
              id="SectionStudent.SectionStudent.s2C"
              values={{
                b: chunks => <strong className={css.bold}>{chunks}</strong>,
              }}
            />
          }
        />
        {/* <EachStep
          className={css.eachStep}
          logo={<img className={css.logo} src={QualityLogo} />}
          title={intl.formatMessage({ id: 'SectionStudent.SectionStudent.s3T' })}
          content={intl.formatMessage({ id: 'SectionStudent.SectionStudent.s3C' })}
        /> */}
        <EachStep
          className={css.eachStep}
          logo={<img className={css.logo} src={GlobeLogo} />}
          title={intl.formatMessage({ id: 'SectionStudent.SectionStudent.s5T' })}
          content={
            <FormattedMessage
              id="SectionStudent.SectionStudent.s5C"
              values={{
                b: chunks => <strong className={css.bold}>{chunks}</strong>,
              }}
            />
          }
          // content={intl.formatMessage({ id: 'SectionStudent.SectionStudent.s5C' })}
        />
        <EachStep
          className={css.eachStep}
          logo={<img className={css.logo} src={MessageLogo} />}
          title={intl.formatMessage({ id: 'SectionStudent.SectionStudent.s4T' })}
          // content={intl.formatMessage({ id: 'SectionStudent.SectionStudent.s4C' })}
          content={
            <FormattedMessage
              id="SectionStudent.SectionStudent.s4C"
              values={{
                b: chunks => <strong className={css.bold}>{chunks}</strong>,
              }}
            />
          }
        />

        <EachStep image={img2} />
      </div>
      <div className={css.buttonContainer}>
        <NamedLink name={isAuth ? 'SearchPage' : 'SignupPage'}>
          {' '}
          <Button className={css.button}>
            <FormattedMessage id="SectionStudent.SectionStudent.buttonText" />
          </Button>
        </NamedLink>
      </div>
    </div>
  );
};

SectionStudent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionStudent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionStudent;
