import React from 'react';
import css from './DescriptionPrivateTourForm.module.css';
import config from '../../config';
import { FieldCheckboxGroup, FieldTextInput, TranslateText } from '../../components';
import { composeValidators, required, requiredFieldArrayCheckbox } from '../../util/validators';
import { convertLabel } from '../../util/reactIntl';
const Activity = ({
  isLanguageEnglish,
  values,
  intl,
  maxLength60Message,
  form,
  descriptionMaxLength,
  TITLE_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  SUBTITLE_MAX_LENGTH,
  maxLengthSubtitleMessage,
}) => {
  return (
    <div>
      {isLanguageEnglish ? (
        <div>
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.title',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.titlePlaceholderMessage',
            })}
            validate={composeValidators(
              required(intl.formatMessage({ id: 'DescriptionPrivateTourForm.Activity.title' })),
              maxLength60Message
            )}
            showCounter={true}
            maxCharacters={TITLE_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.es_title}
            fromKey="es_title"
            toKey="title"
            target="en"
            id="title"
          />

          <FieldTextInput
            id="es_title"
            name="es_title"
            className={css.title}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishTitle',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.esTitlePlaceholderMessage',
            })}
            validate={composeValidators(
              required(intl.formatMessage({ id: 'DescriptionPrivateTourForm.Activity.title' })),
              maxLength60Message
            )}
            showCounter={true}
            maxCharacters={TITLE_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.title}
            fromKey="title"
            toKey="es_title"
            target="es"
            id="es_title"
          />
          <FieldTextInput
            id="subtitle"
            name="subtitle"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.subTitlePlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({ id: 'DescriptionPrivateTourForm.Activity.subtitleRequired' })
              ),
              maxLengthSubtitleMessage
            )}
            showCounter={true}
            maxCharacters={SUBTITLE_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.es_subtitle}
            fromKey="es_subtitle"
            toKey="subtitle"
            target="en"
            id="subtitle"
          />
          <FieldTextInput
            id="es_subtitle"
            name="es_subtitle"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleSpanishLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.esTitlePlaceholderMEssage',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({ id: 'DescriptionPrivateTourForm.Activity.subtitleRequired' })
              ),
              maxLengthSubtitleMessage
            )}
            showCounter={true}
            maxCharacters={SUBTITLE_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.subtitle}
            fromKey="subtitle"
            toKey="es_subtitle"
            target="es"
            id="es_subtitle"
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.descriptionLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.descriptionPlaceholderMessage',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.Activity.descriptionRequired',
                })
              ),
              descriptionMaxLength
            )}
            showCounter={true}
            maxCharacters={DESCRIPTION_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.es_description}
            fromKey="es_description"
            toKey="description"
            target="en"
            id="description"
          />
          <FieldTextInput
            id="es_description"
            name="es_description"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishDescriptionLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.esDescriptionPlaceholderMessage',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.Activity.descriptionRequired',
                })
              ),
              descriptionMaxLength
            )}
            showCounter={true}
            maxCharacters={DESCRIPTION_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.description}
            fromKey="description"
            toKey="es_description"
            target="es"
            id="es_description"
          />
        </div>
      ) : (
        <div>
          <FieldTextInput
            id="es_title"
            name="es_title"
            className={css.title}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishTitle',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.titlePlaceholderMessage',
            })}
            validate={composeValidators(
              required(intl.formatMessage({ id: 'DescriptionPrivateTourForm.Activity.title' })),
              maxLength60Message
            )}
            showCounter={true}
            maxCharacters={TITLE_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.title}
            fromKey="title"
            toKey="es_title"
            target="es"
            id="es_title"
          />
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="textarea"
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.titleMessageForPlaceholder',
            })}
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.title',
            })}
            validate={composeValidators(
              required(intl.formatMessage({ id: 'DescriptionPrivateTourForm.Activity.title' })),
              maxLength60Message
            )}
            showCounter={true}
            maxCharacters={TITLE_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.es_title}
            fromKey="es_title"
            toKey="title"
            target="en"
            id="title"
          />

          <FieldTextInput
            id="es_subtitle"
            name="es_subtitle"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleSpanishLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.subTitlePlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({ id: 'DescriptionPrivateTourForm.Activity.subtitleRequired' })
              ),
              maxLengthSubtitleMessage
            )}
            showCounter={true}
            maxCharacters={SUBTITLE_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.subtitle}
            fromKey="subtitle"
            toKey="es_subtitle"
            target="es"
            id="es_subtitle"
          />
          <FieldTextInput
            id="subtitle"
            name="subtitle"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.subtitleMessageForPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({ id: 'DescriptionPrivateTourForm.Activity.subtitleRequired' })
              ),
              maxLengthSubtitleMessage
            )}
            showCounter={true}
            maxCharacters={SUBTITLE_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.es_subtitle}
            fromKey="es_subtitle"
            toKey="subtitle"
            target="en"
            id="subtitle"
          />

          <FieldTextInput
            id="es_description"
            name="es_description"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishDescriptionLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.descriptionPlaceholderMessage',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.Activity.descriptionRequired',
                })
              ),
              descriptionMaxLength
            )}
            showCounter={true}
            maxCharacters={DESCRIPTION_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.description}
            fromKey="description"
            toKey="es_description"
            target="es"
            id="es_description"
          />
          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.descriptionLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.Activity.descriptionEngPlaceholderMessage',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.Activity.descriptionRequired',
                })
              ),
              descriptionMaxLength
            )}
            showCounter={true}
            maxCharacters={DESCRIPTION_MAX_LENGTH}
          />
          <TranslateText
            form={form}
            value={values?.es_description}
            fromKey="es_description"
            toKey="description"
            target="en"
            id="description"
          />
        </div>
      )}
      <FieldCheckboxGroup
        id="immersion_program"
        name="immersion_program"
        label={intl.formatMessage({
          id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.immersionProgramLabel',
        })}
        options={convertLabel(config.custom.immersionProgramOptions, intl)}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage({
            id: 'DescriptionPrivateTourForm.Activity.immersionProgramRequiredMessage',
          })
        )}
      />
    </div>
  );
};

export default Activity;
