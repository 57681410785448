import React, { Component, useEffect, useState } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  required,
  bookingDatesRequired,
  composeValidators,
  notGreaterThanLessons,
  minimumStayRequired,
  maximumStayRequired,
  allowBookingBeforeStart,
  notLessthanLessons,
} from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import {
  FieldCheckbox,
  FieldInputCounter,
  FieldSelectModern,
  Form,
  IconSpinner,
  PrimaryButton,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import arrayMutators from 'final-form-arrays';

import debounce from 'lodash/debounce';

import css from './BookingDatesForm.module.css';
import { formatMoney } from '../../util/currency';
import FieldTourInput from '../../components/FieldDateRangeInput/FieldTourInput';
const { Money, UUID } = sdkTypes;
const identity = v => v;

const debouncedOnFetch = debounce(function(onFetchTransactionLineItems, params) {
  onFetchTransactionLineItems(params);
}, 1000);

export class BookingTourFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { startDate, endDate } = e.bookingDates || {};
    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(previousValues, formValues) {
    const { startDate, endDate } =
      formValues.values && formValues.values.bookingDates ? formValues.values.bookingDates : {};
    const { listing } = this.props;
    const priceType = listing.attributes.publicData?.priceType;
    const isFixedPrice = priceType === 'fixed_price';

    const {
      'booking-type': bookingType,
      additional_services = [],
      additional_lessons = 0,
      people,
      pricePerPerson,
    } = formValues.values || {};
    const validation = isFixedPrice ? people : pricePerPerson;
    if (!validation) {
      return;
    }
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    const extras = Object.keys(formValues?.values)
      .filter(key => key.startsWith('extra'))
      .map(key => formValues?.values[key]);
    const extraArray = extras?.length
      ? extras?.map(e => ({
          extra: +e.value?.split('_')[0],
          key: +e.value?.split('_')[0],
          quantity: e.value?.split('_')[1],
        }))
      : null;

    if (startDate && endDate && !this.props.fetchLineItemsInProgress) {
      const params = {
        bookingData: {
          startDate,
          endDate,
          bookingType,
          additional_services,
          additional_lessons,
          people: people ? people?.value : 1,
          extras: extraArray,
          pricePerPerson,
        },
        listingId,
        isOwnListing,

        // priceFromDatePriceArray,
      };

      if (previousValues.additional_lessons != formValues.values.additional_lessons) {
        debouncedOnFetch(this.props.onFetchTransactionLineItems, params);
      } else {
        this.props.onFetchTransactionLineItems(params);
      }
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        mutators={{ ...arrayMutators }}
        {...rest}
        unitPrice={unitPrice}
        initialValues={{ additional_lessons: 0 }}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            paymentMethods,
            listing,
            invalid,
            bufferTime,
            disabled,
            form,
            errors,
            language,
            isActivity,
          } = fieldRenderProps;

          const giveOptions = item => {
            const priceWithCommision =
              item?.priceWithCommission || item?.price + (item?.price * 10) / 100;
            const numberOfPersons = +item?.number;
            const options = [];

            for (let p = 1; p <= numberOfPersons; p++) {
              const price = priceWithCommision * p;
              const label = `${formatMoney(
                intl,
                new Money(price, config.currency)
              )} ${intl.formatMessage(
                {
                  id:
                    p > 1
                      ? 'BookingDatesForm.BookingTourForm.forPeople'
                      : 'BookingDatesForm.BookingTourForm.forperson',
                },
                { p }
              )}`;
              const value = `${item?.id}_${p}`;
              const object = {
                label: label,
                value,
              };
              options.push(object);
            }
            return options?.reverse();
          };
          const isError = !!errors?.additional_lessons;
          const displayName = listing?.author?.attributes?.profile?.displayName || '';
          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};
          const priceType = listing.attributes.publicData?.priceType;
          const pricePerPerson = listing.attributes.publicData?.pricePerPerson
            ? listing.attributes.publicData?.pricePerPerson
                ?.sort((a, b) => b.person - a.person)
                ?.filter(item => item.price > 0)
            : [];
          const extras = listing.attributes.publicData?.extra;
          const extrasWithPrice = extras?.filter(p => p?.price);
          const duration = listing.attributes.publicData?.duration || 0;
          const people = listing.attributes.publicData?.people;
          const isLanguageEnglish = language === 'english';
          const extraFields =
            extras?.length > 0
              ? extras?.map((item, index) => {
                  // form.change(
                  //   `extra_${item?.id}_counter`,
                  //   values?.[`extra_${item?.id}_counter`] || 1
                  // );
                  return (
                    <div className={css.packOfLessons}>
                      <div className={css.packOfLessonName}>
                        {isLanguageEnglish ? item?.name : item?.es_name || item?.name}{' '}
                      </div>
                      <FieldSelectModern
                        id={`extra_${item.id}`}
                        name={`extra_${item.id}`}
                        placeholder={intl.formatMessage({
                          id: 'BookingDatesForm.BookingTourForm.selectPlaceholder',
                        })}
                        options={giveOptions(item) || []}
                        isSearchable={false}
                        className={css.extraField}
                      />
                      {/* <FieldCheckbox
                        id={`extra_${item?.id}`}
                        name={`extra_${item?.id}`}
                        label={
                          <div className={css.packOfLesson}>
                            <div className={css.packOfLessonName}>
                              {isLanguageEnglish ? item?.name : item?.es_name || item?.name}{' '}
                            </div>
                            <div className={css.packOfLessonPrice}>
                              {formatMoney(
                                intl,
                                new Money(item?.price + (item?.price * 10) / 100, config.currency)
                              )}
                            </div>
                          </div>
                        }
                      />
                      <FieldInputCounter
                        className={css.counter}
                        id={`extra_${item?.id}_counter`}
                        name={`extra_${item?.id}_counter`}
                        type="number"
                        min={0}
                        max={item?.number}
                      /> */}
                    </div>
                  );
                })
              : null;
          let peopleOptions = [];
          if (people) {
            for (let i = 1; i <= people; i++) {
              peopleOptions.push({
                key: i,
                label: i,
                value: i,
              });
            }
          }
          const isFixedPrice = priceType === 'fixed_price';
          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                }
              : null;

          const cashless = values?.['booking-type'] == 'cash_on_arrival' ? true : false;

          const submitDisabled =
            invalid || disabled || !startDate || !endDate || fetchLineItemsInProgress;
          // || !values?.['booking-type'];

          const showEstimatedBreakdown =
            bookingData &&
            lineItems &&
            !fetchLineItemsInProgress &&
            !fetchLineItemsError &&
            !submitDisabled;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                cashless={cashless}
                isActivity={isActivity}
              />
            </div>
          ) : null;
          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(fieldRenderProps.values, values);
                }}
              />

              <FieldTourInput
                className={css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                startDatePlaceholderText={startDatePlaceholderText}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                endDatePlaceholderText={endDatePlaceholderText}
                focusedInput={this.state.focusedInput}
                onFocusedInputChange={this.onFocusedInputChange}
                format={identity}
                timeSlots={timeSlots}
                bufferTime={bufferTime}
                duration={isActivity ? 1 : duration}
                useMobileMargins
                validate={composeValidators(
                  required(requiredMessage),
                  bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                )}
                disabled={fetchLineItemsInProgress}
              />

              <div className={css.bookingTypesContainer}>
                {isFixedPrice ? (
                  <div>
                    <FieldSelectModern
                      id="people"
                      name="people"
                      label={intl.formatMessage({
                        id: 'BookingDatesForm.BookingTourForm.personsLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'BookingDatesForm.BookingTourForm.selectPlaceholder',
                      })}
                      className={css.field}
                      options={peopleOptions}
                      validate={required(
                        intl.formatMessage({
                          id: 'BookingDatesForm.BookingTourForm.personRequiredMessage',
                        })
                      )}
                    />
                    {extrasWithPrice && extrasWithPrice.length > 0 ? (
                      <div className={css.field}>
                        <p className={css.extrasLabel}>
                          {intl.formatMessage({
                            id: 'BookingDatesForm.BookingTourForm.extrasLabelMessage',
                          })}
                        </p>
                        {extraFields}
                      </div>
                    ) : // <FieldSelectModern
                    //   id="extras"
                    //   name="extras"
                    //   label={intl.formatMessage({
                    //     id: 'BookingDatesForm.BookingTourForm.extrasLabelMessage',
                    //   })}
                    //   className={css.field}
                    //   options={extrasWithPrice?.map(extra => ({
                    //     key: extra?.id,
                    //     label: `${extra?.name} - ${formatMoney(
                    //       intl,
                    //       new Money(extra?.price + extra?.price * 0.1, config.currency)
                    //     )} `,
                    //     value: extra?.id,
                    //   }))}
                    //   // validate={required('Please select extras')}
                    // />
                    null}
                  </div>
                ) : (
                  <div>
                    {pricePerPerson && pricePerPerson?.length > 0 ? (
                      <FieldSelectModern
                        id="pricePerPerson"
                        name="pricePerPerson"
                        label={
                          isActivity
                            ? intl.formatMessage({
                                id: 'BookingDatesForm.BookingTourForm.activityTourPriceLabel',
                              })
                            : intl.formatMessage({
                                id: 'BookingDatesForm.BookingTourForm.tourPrice',
                              })
                        }
                        className={css.field}
                        placeholder={intl.formatMessage({
                          id: 'BookingDatesForm.BookingTourForm.selectPlaceholder',
                        })}
                        options={pricePerPerson?.map(price => ({
                          key: price?.id,
                          label: `${formatMoney(
                            intl,
                            new Money(price?.price * +price?.person, config.currency)
                          )} ${intl.formatMessage({
                            id: 'BookingDatesForm.BookingTourForm.for',
                          })} ${price?.person} ${intl.formatMessage({ id: 'PersonsLabel' })}${
                            price?.person > 1 ? 's' : ''
                          }`,
                          value: price?.id,
                        }))}
                        validate={required(
                          isActivity
                            ? intl.formatMessage({
                                id: 'BookingDatesForm.BookingTourForm.tourActivityRequired',
                              })
                            : intl.formatMessage({
                                id: 'BookingDatesForm.BookingTourForm.priceRequiredMessage',
                              })
                        )}
                      />
                    ) : null}
                    {extrasWithPrice && extrasWithPrice.length > 0 ? (
                      <div className={css.field}>
                        <p className={css.extrasLabel}>
                          {intl.formatMessage({
                            id: 'BookingDatesForm.BookingTourForm.extrasLabelMessage',
                          })}
                        </p>
                        {extraFields}
                      </div>
                    ) : // <FieldSelectModern
                    //   id="extras"
                    //   name="extras"
                    //   label={intl.formatMessage({
                    //     id: 'BookingDatesForm.BookingTourForm.extrasLabel',
                    //   })}
                    //   className={css.field}
                    //   options={extras?.map(extra => ({
                    //     key: extra?.id,
                    //     label: `${extra?.name} - ${formatMoney(
                    //       intl,
                    //       new Money(extra?.price + extra?.price * 0.1, config.currency)
                    //     )} `,
                    //     value: extra?.id,
                    //   }))}
                    //   // validate={required('Please select extras')}
                    // />
                    null}
                  </div>
                )}
              </div>

              {isError ? null : bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={submitDisabled}>
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTourFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTourFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTourForm = compose(injectIntl)(BookingTourFormComponent);
BookingTourForm.displayName = 'BookingTourForm';

export default BookingTourForm;
