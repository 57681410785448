import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileLanguage } from '../../ducks/user.duck';
import { injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import css from './ChangeLanguageForm.module.css';
import { GrLanguage } from 'react-icons/gr';
import NamedRedirect from '../NamedRedirect/NamedRedirect';
const ChangeLanguageFormComponent = props => {
  const { intl, params, history } = props;
  const [value, setValue] = useState('english');
  const { LandingPage, currentUser, isMobile = false } = props;
  const dispatch = useDispatch();
  const state = useSelector(state => state.user);
  const { currentUserInpgrogress } = state;
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'english';

    if (cookieValue == 'english') {
      setValue('english');
    } else {
      setValue('spanish');
    }
  }, []);
  if (currentUserInpgrogress) {
    return null;
  }
  const handleOnChange = e => {
    const selectedOption = e.target.value;
    setValue(selectedOption);
    dispatch(updateProfileLanguage(selectedOption));
    document.cookie = 'language=' + selectedOption;

    if (window.location.pathname == '/esp' || window.location.pathname == '/en') {
      window.location = window.location.origin;

      return;
    }
    window.location.reload(true);
  };

  return (
    <div className={isMobile ? css.mobile : currentUser ? css.mobile : css.languageComtainer}>
      <div>
        <GrLanguage className={css.locationIcon} />
      </div>
      <div>
        <select
          className={LandingPage ? css.normalSelectLandingPage : css.normalSelect}
          onChange={e => handleOnChange(e)}
        >
          <option value="english" selected={value == 'english' ? true : false}>
            {intl.formatMessage({ id: 'ChangeLanguageForm.ChangeLanguageForm.EnglishLanguage' })}
          </option>
          <option value="spanish" selected={value === 'spanish' ? true : false}>
            {intl.formatMessage({ id: 'ChangeLanguageForm.ChangeLanguageForm.spanishLanguage' })}
          </option>
        </select>
      </div>
    </div>
  );
};

const ChangeLanguageForm = compose(
  withRouter,
  injectIntl
)(ChangeLanguageFormComponent);

export default ChangeLanguageForm;
