import React from 'react';
import css from './TranslateText.module.css';
import { MdGTranslate } from 'react-icons/md';
import { SecondaryButton } from '../Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { translateTextFromApi } from '../../containers/EditListingPage/EditListingPage.duck';
import { FormattedMessage } from 'react-intl';
const TranslateText = props => {
  const { form, value, fromKey, toKey, target } = props;
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { translateTextLoading, translateTextError, translateTextKey } = state.EditListingPage;

  return value ? (
    <div>
      {translateTextError && translateTextKey === fromKey ? (
        <p className={css.error}>{translateTextError?.message}</p>
      ) : null}
      <div className={css.translateContainer}>
        <SecondaryButton
          type="button"
          className={css.translateButton}
          inProgress={translateTextLoading && translateTextKey === fromKey}
          onClick={async () => {
            const translate = await dispatch(translateTextFromApi(value, fromKey, target));
            const translateText = translate || value;
            form.change(toKey, translateText);
          }}
        >
          <MdGTranslate className={css.icon} />
          {target === 'en' ? (
            <FormattedMessage id="TranslateText.TranslateText.spanishLabel" />
          ) : (
            <FormattedMessage id="TranslateText.TranslateText.englishLabel" />
          )}
        </SecondaryButton>
      </div>
    </div>
  ) : null;
};

export default TranslateText;
