import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import config from '../../config';
import { Button, FieldInputCounter, FieldTextInput, Form, TranslateText } from '../../components';
import { propTypes } from '../../util/types';
import css from './DurationForm.module.css';
import { composeValidators, required, zeroNumberValidate } from '../../util/validators';
import { PRIVATE_TOUR } from '../../components/EditListingWizard/EditListingWizardTab';

const DurationFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        invalid,
        form,
        values,
        isLanguageEnglish,
        intl,
        category,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="DurationForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="DurationForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          {category === PRIVATE_TOUR ? (
            <div className={css.counterContainer}>
              <FieldInputCounter
                className={css.counter}
                id="duration"
                name="duration"
                type="number"
                label={intl.formatMessage({ id: 'DurationForm.DurationForm.durationLabel' })}
                min={2}
                max={15}
                validate={composeValidators(
                  zeroNumberValidate(
                    intl.formatMessage({ id: 'DurationForm.DurationForm.durationZeroNotAllowed' })
                  ),
                  required(intl.formatMessage({ id: 'DurationForm.DurationForm.durationRequired' }))
                )}
                isBlock={true}
              />
              <FieldInputCounter
                className={css.counter}
                id="people"
                name="people"
                type="number"
                label={intl.formatMessage({ id: 'DurationForm.DurationForm.numberOfPeopleLabel' })}
                min={1}
                max={6}
                validate={composeValidators(
                  zeroNumberValidate(
                    intl.formatMessage({
                      id: 'DurationForm.DurationForm.peopleMustbeGreaterThanValidation',
                    })
                  ),
                  required(
                    intl.formatMessage({ id: 'DurationForm.DurationForm.numberOfPeopleRequired' })
                  )
                )}
                isBlock={true}
              />
              {isLanguageEnglish ? (
                <div>
                  <FieldTextInput
                    id="transportation"
                    name="transportation"
                    className={css.counter}
                    label={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationLabel',
                    })}
                    type="textarea"
                    placeholder={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationPlaceholder',
                    })}
                    validate={required(
                      intl.formatMessage({ id: 'DurationForm.DurationForm.transportationRequired' })
                    )}
                  />
                  <TranslateText
                    form={form}
                    value={values?.es_transportation}
                    fromKey="es_transportation"
                    toKey="transportation"
                    target="en"
                    id="transportation"
                  />
                  <FieldTextInput
                    id="es_transportation"
                    name="es_transportation"
                    className={css.counter}
                    label={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transporationSpanishLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationSpanishPlaceholder',
                    })}
                    type="textarea"
                    validate={required(
                      intl.formatMessage({ id: 'DurationForm.DurationForm.transportationRequired' })
                    )}
                  />
                  <TranslateText
                    form={form}
                    value={values?.transportation}
                    fromKey="transportation"
                    toKey="es_transportation"
                    target="es"
                    id="es_transportation"
                  />
                </div>
              ) : (
                <div>
                  <FieldTextInput
                    id="es_transportation"
                    name="es_transportation"
                    className={css.counter}
                    label={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transporationSpanishLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationPlaceholder',
                    })}
                    type="textarea"
                    validate={required(
                      intl.formatMessage({ id: 'DurationForm.DurationForm.transportationRequired' })
                    )}
                  />
                  <TranslateText
                    form={form}
                    value={values?.transportation}
                    fromKey="transportation"
                    toKey="es_transportation"
                    target="es"
                    id="es_transportation"
                  />
                  <FieldTextInput
                    id="transportation"
                    name="transportation"
                    className={css.counter}
                    label={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationSpanishPlaceholder',
                    })}
                    type="textarea"
                    validate={required(
                      intl.formatMessage({ id: 'DurationForm.DurationForm.transportationRequired' })
                    )}
                  />
                  <TranslateText
                    form={form}
                    value={values?.es_transportation}
                    fromKey="es_transportation"
                    toKey="transportation"
                    target="en"
                    id="transportation"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className={css.counterContainer}>
              <FieldInputCounter
                className={css.counter}
                id="duration"
                name="duration"
                type="number"
                label={intl.formatMessage({
                  id: 'DurationForm.DurationForm.durationActivityLabel',
                })}
                min={1}
                max={10}
                validate={composeValidators(
                  zeroNumberValidate(
                    intl.formatMessage({ id: 'DurationForm.DurationForm.durationZeroNotAllowed' })
                  ),
                  required(intl.formatMessage({ id: 'DurationForm.DurationForm.durationRequired' }))
                )}
                isBlock={true}
              />
              <FieldInputCounter
                className={css.counter}
                id="people"
                name="people"
                type="number"
                label={intl.formatMessage({ id: 'DurationForm.DurationForm.numberOfPeopleLabel' })}
                min={1}
                max={6}
                validate={composeValidators(
                  zeroNumberValidate(
                    intl.formatMessage({
                      id: 'DurationForm.DurationForm.peopleMustbeGreaterThanValidation',
                    })
                  ),
                  required(
                    intl.formatMessage({ id: 'DurationForm.DurationForm.numberOfPeopleRequired' })
                  )
                )}
                isBlock={true}
              />
            </div>
          )}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

DurationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

DurationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const DurationForm = DurationFormComponent;

export default injectIntl(DurationForm);
