import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import config from '../../config';
import { FieldArray } from 'react-final-form-arrays';
import { Button, FieldCurrencyInput, FieldTextInput, Form, TranslateText } from '../../components';
import { propTypes } from '../../util/types';
import css from './TransportationForm.module.css';
import {
  composeValidators,
  maxLength,
  maxLengthNumber,
  required,
  zeroNumberValidate,
} from '../../util/validators';
import { AiTwotoneDelete } from 'react-icons/ai';
const TransportationFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        invalid,
        form,
        values,
        isLanguageEnglish,
        intl,
        isPrivateTour,
        people,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const priceRequired = required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const priceValidators = priceRequired;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="TransportationForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="TransportationForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <div>
            <FieldArray name="included">
              {({ fields }) => (
                <div>
                  <h3 className={css.languageHeading}>
                    {intl.formatMessage({
                      id: 'TransportationForm.TransportationForm.includedHeading',
                    })}
                  </h3>
                  {isPrivateTour ? (
                    <p className={css.tipnote}>
                      {intl.formatMessage({
                        id: 'TransportationForm.TransportationForm.includedSubheading',
                      })}
                    </p>
                  ) : (
                    <p className={css.tipnote}>
                      {intl.formatMessage({
                        id: 'TransportationForm.TransportationForm.includedNoteMessage',
                      })}
                    </p>
                  )}
                  {fields.map((name, index) => (
                    <div key={name} className={css.fieldArrayContainer}>
                      <div>
                        {isLanguageEnglish ? (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.name`}
                              name={`${name}.name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.nameOfIncludedLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'TransportationForm.TransportationForm.nameOfIncludedPlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />
                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.included?.[index]?.es_name}
                              fromKey={`${name}.es_name`}
                              toKey={`${name}.name`}
                              target="en"
                              id={`${name}.name`}
                            />
                          </>
                        ) : (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.es_name`}
                              name={`${name}.es_name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.nameOfIncludedSpanish',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'TransportationForm.TransportationForm.nameOfIncludedPlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />

                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.included?.[index]?.name}
                              fromKey={`${name}.name`}
                              toKey={`${name}.es_name`}
                              target="es"
                              id={`${name}.es_name`}
                            />
                          </>
                        )}
                      </div>
                      <div>
                        {isLanguageEnglish ? (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.es_name`}
                              name={`${name}.es_name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.nameOfIncludedSpanish',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'TransportationForm.TransportationForm.nameOfIncludedSpanishPlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />

                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.included?.[index]?.name}
                              fromKey={`${name}.name`}
                              toKey={`${name}.es_name`}
                              target="es"
                              id={`${name}.es_name`}
                            />
                          </>
                        ) : (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.name`}
                              name={`${name}.name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.nameOfIncludedLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'TransportationForm.TransportationForm.nameOfIncludedSpanishPlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />
                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.included?.[index]?.es_name}
                              fromKey={`${name}.es_name`}
                              toKey={`${name}.name`}
                              target="en"
                              id={`${name}.name`}
                            />
                          </>
                        )}
                      </div>
                      <p className={css.close} onClick={() => fields.remove(index)}>
                        <AiTwotoneDelete className={css.delete} />
                      </p>
                    </div>
                  ))}

                  <p
                    className={css.addAnotherLanguage}
                    onClick={() => fields.push({ name: '', es_name: '' })}
                  >
                    {intl.formatMessage({
                      id: 'TransportationForm.TransportationForm.includedAddItem',
                    })}
                  </p>
                </div>
              )}
            </FieldArray>
            <FieldArray name="notIncluded">
              {({ fields }) => (
                <div>
                  <h3 className={css.languageHeading}>
                    {intl.formatMessage({
                      id: 'TransportationForm.TransportationForm.notIncludedHeading',
                    })}
                  </h3>
                  {isPrivateTour ? (
                    <p className={css.tipnote}>
                      {intl.formatMessage({
                        id: 'TransportationForm.TransportationForm.notIncludedNote',
                      })}
                    </p>
                  ) : (
                    <p className={css.tipnote}>
                      {intl.formatMessage({
                        id: 'TransportationForm.TransportationForm.notIncludedMessageNote',
                      })}
                    </p>
                  )}
                  {fields.map((name, index) => (
                    <div key={name} className={css.fieldArrayContainer}>
                      <div>
                        {isLanguageEnglish ? (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.name`}
                              name={`${name}.name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.notIncludedNameLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'TransportationForm.TransportationForm.notIncludedNamePlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />
                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.notIncluded?.[index]?.es_name}
                              fromKey={`${name}.es_name`}
                              toKey={`${name}.name`}
                              target="en"
                              id={`${name}.name`}
                            />
                          </>
                        ) : (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.es_name`}
                              name={`${name}.es_name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.notIncludedSpanishLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'TransportationForm.TransportationForm.notIncludedNamePlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />

                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.notIncluded?.[index]?.name}
                              fromKey={`${name}.name`}
                              toKey={`${name}.es_name`}
                              target="es"
                              id={`${name}.es_name`}
                            />
                          </>
                        )}
                      </div>
                      <div>
                        {isLanguageEnglish ? (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.es_name`}
                              name={`${name}.es_name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.notIncludedSpanishLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'TransportationForm.TransportationForm.notIncludedSpanishPlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />

                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.notIncluded?.[index]?.name}
                              fromKey={`${name}.name`}
                              toKey={`${name}.es_name`}
                              target="es"
                              id={`${name}.es_name`}
                            />
                          </>
                        ) : (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.name`}
                              name={`${name}.name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.notIncludedNameLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'TransportationForm.TransportationForm.notIncludedSpanishPlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />
                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.notIncluded?.[index]?.es_name}
                              fromKey={`${name}.es_name`}
                              toKey={`${name}.name`}
                              target="en"
                              id={`${name}.name`}
                            />
                          </>
                        )}
                      </div>
                      <p className={css.close} onClick={() => fields.remove(index)}>
                        <AiTwotoneDelete className={css.delete} />
                      </p>
                    </div>
                  ))}

                  <p
                    className={css.addAnotherLanguage}
                    onClick={() => fields.push({ name: '', es_name: '' })}
                  >
                    {intl.formatMessage({
                      id: 'TransportationForm.TransportationForm.notIncludedLabel',
                    })}
                  </p>
                </div>
              )}
            </FieldArray>
            <FieldArray name="extra">
              {({ fields }) => (
                <div>
                  <h3 className={css.languageHeading}>
                    {intl.formatMessage({
                      id: 'TransportationForm.TransportationForm.extraHeading',
                    })}
                  </h3>
                  {isPrivateTour ? (
                    <p className={css.tipnote}>
                      {intl.formatMessage({
                        id: 'TransportationForm.TransportationForm.extraSubheading',
                      })}
                    </p>
                  ) : (
                    <p className={css.tipnote}>
                      {intl.formatMessage({
                        id: 'TransportationForm.TransportationForm.noteForExtraMessage',
                      })}
                    </p>
                  )}
                  {fields.map((name, index) => (
                    <div key={name} className={css.fieldArrayContainer}>
                      <div>
                        {isLanguageEnglish ? (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.name`}
                              name={`${name}.name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.extranameLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.extraNamePlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />
                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.extra?.[index]?.es_name}
                              fromKey={`${name}.es_name`}
                              toKey={`${name}.name`}
                              target="en"
                              id={`${name}.name`}
                            />
                          </>
                        ) : (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.es_name`}
                              name={`${name}.es_name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.extraSpanishname',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.extraNamePlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />

                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.extra?.[index]?.name}
                              fromKey={`${name}.name`}
                              toKey={`${name}.es_name`}
                              target="es"
                              id={`${name}.es_name`}
                            />
                          </>
                        )}
                      </div>
                      <div>
                        {isLanguageEnglish ? (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.es_name`}
                              name={`${name}.es_name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.extraSpanishname',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.extraSpanishPlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />

                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.extra?.[index]?.name}
                              fromKey={`${name}.name`}
                              toKey={`${name}.es_name`}
                              target="es"
                              id={`${name}.es_name`}
                            />
                          </>
                        ) : (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.name`}
                              name={`${name}.name`}
                              label={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.extranameLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'TransportationForm.TransportationForm.extraSpanishPlaceholder',
                              })}
                              validate={required(
                                intl.formatMessage({
                                  id:
                                    'TransportationForm.TransportationForm.nameOfIncludedRequired',
                                })
                              )}
                            />
                            <TranslateText
                              form={formRenderProps.form}
                              value={values?.extra?.[index]?.es_name}
                              fromKey={`${name}.es_name`}
                              toKey={`${name}.name`}
                              target="en"
                              id={`${name}.name`}
                            />
                          </>
                        )}
                      </div>
                      <FieldTextInput
                        className={css.language}
                        name={`${name}.number`}
                        id={`${name}.number`}
                        label={intl.formatMessage({
                          id: 'TransportationForm.TransportationForm.numberOfExtrasLabel',
                        })}
                        type="number"
                        placeholder={intl.formatMessage({
                          id: 'TransportationForm.TransportationForm.numberOfExtrasPlaceholder',
                        })}
                        min={0}
                        validate={composeValidators(
                          required(
                            intl.formatMessage({
                              id: 'TransportationForm.TransportationForm.numberOfExtrasRequired',
                            })
                          ),
                          maxLengthNumber(
                            intl.formatMessage(
                              {
                                id:
                                  'TransportationForm.TransportationForm.numberOfExtrasMaxValidationMessage',
                              },
                              { people: people }
                            ),
                            people
                          )
                        )}
                      />
                      <FieldCurrencyInput
                        id={`${name}.price`}
                        name={`${name}.price`}
                        className={css.language}
                        label={intl.formatMessage({
                          id: 'TransportationForm.TransportationForm.priceLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'TransportationForm.TransportationForm.pricePlaceholder',
                        })}
                        currencyConfig={config.currencyConfig}
                        validate={priceValidators}
                        autoComplete="off"
                      />
                      <p className={css.close} onClick={() => fields.remove(index)}>
                        <AiTwotoneDelete className={css.delete} />
                      </p>
                    </div>
                  ))}

                  <p
                    className={css.addAnotherLanguage}
                    onClick={() => fields.push({ name: '', es_name: '' })}
                  >
                    {intl.formatMessage({
                      id: 'TransportationForm.TransportationForm.extraAddItem',
                    })}
                  </p>
                </div>
              )}
            </FieldArray>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

TransportationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

TransportationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const TransportationForm = TransportationFormComponent;

export default injectIntl(TransportationForm);
