import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ChangeLanguageForm,
  ExternalLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    currentUserInpgrogress,
    cookieValue,
    isAboutUsPage,
  } = props;
  const [mounted, setMounted] = useState(false);
  const isLanguageEnglish = cookieValue === 'english';
  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="changeLanguage">
          {currentUser ? null : (
            <ChangeLanguageForm className={css.changeLanguage} currentUser={currentUser} />
          )}
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <span>
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDot}
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="SelectCategoryPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('SelectCategoryPage'))}
            name="SelectCategoryPage"
            // name="NewListingPageWithCategories"
            // params={{ category: 'category1' }}
          >
            <span className={css.menuItemBorder} />
            <span>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink} style={{ marginTop: '1rem' }}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.signupLink} style={{ marginTop: '1rem' }}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      {currentPage == 'LandingPage' ? <div className={css.greenShadow}></div> : null}
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {currentPage == 'SearchPage' ? search : null}
      {currentPage == 'SearchPage' ? (
        <div className={css.linksNew}>
          <NamedLink className={css.createListingLink} name="AboutUs">
            <span className={css.createListing}>
              {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.aboutUs' })}
            </span>
          </NamedLink>
          <NamedLink className={css.createListingLink} name="ContactUsPage">
            <span className={css.createListing}>
              {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.contact' })}
            </span>
          </NamedLink>
        </div>
      ) : (
        <>
          <div className={css.linksNew}>
            <NamedLink
              className={css.createListingLink}
              name="SearchPage"
              to={{
                search: '?pub_category=activity',
              }}
            >
              <span className={css.createListing}>
                {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.activities' })}
              </span>
            </NamedLink>

            <NamedLink
              className={css.createListingLink}
              name="SearchPage"
              to={{
                search: '?pub_category=category1',
              }}
            >
              <span className={css.createListing}>
                {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.stays' })}
              </span>
            </NamedLink>
            <NamedLink
              className={css.createListingLink}
              name="SearchPage"
              to={{
                search: '?pub_category=private_tours',
              }}
            >
              <span className={css.createListing}>
                {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.programmes' })}
              </span>
            </NamedLink>
            <NamedLink className={css.createListingLink} name="AboutUs">
              <span className={css.createListing}>
                {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.aboutUs' })}
              </span>
            </NamedLink>

            <NamedLink className={css.createListingLink} name="ContactUsPage">
              <span className={css.createListing}>
                {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.contact' })}
              </span>
            </NamedLink>
          </div>
          {/* <div className={css.linksNew}>
            <NamedLink className={css.createListingLink} name="AboutUs">
              <span className={css.createListing}>
                {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.aboutUs' })}
              </span>
            </NamedLink>

            <NamedLink className={css.createListingLink} name="ContactUsPage">
              <span className={css.createListing}>
                {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.contact' })}
              </span>
            </NamedLink>
          </div> */}
        </>
      )}
      {/* <div className={css.linksContainer}> */}
      {/* <ExternalLink
        className={css.createListingLink}
        href={
          isLanguageEnglish
            ? 'https://community.lingobnb.com/about-us/'
            : 'https://community.lingobnb.com/sobre-nosotros/'
        }
      >
        <span className={css.createListing}>
          {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.aboutUsLink' })}
        </span>
      </ExternalLink>
      <ExternalLink
        className={css.createListingLink}
        href={
          isLanguageEnglish
            ? 'https://community.lingobnb.com/crowdfunding-lottery/'
            : 'https://community.lingobnb.com/sorteo-crowdfunding/'
        }
      >
        <span className={css.createListing}>
          {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.crowdFundingLotteryLabel' })}
        </span>
      </ExternalLink>
      <ExternalLink
        className={css.createListingLink}
        href={
          isLanguageEnglish
            ? 'https://community.lingobnb.com/news/'
            : 'https://community.lingobnb.com/noticias/'
        }
      >
        <span className={css.createListing}>
          {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.newsLink' })}
        </span>
      </ExternalLink>
      <ExternalLink
        className={css.createListingLink}
        href={
          isLanguageEnglish
            ? 'https://community.lingobnb.com/'
            : 'https://community.lingobnb.com/inicio/'
        }
      >
        <span className={css.createListing}>
          {intl.formatMessage({ id: 'TopbarDesktop.TopbarDesktop.communityLabel' })}
        </span>
      </ExternalLink> */}
      <div className={css.linksContainer}>
        {/* {!currentUser && !currentUserInpgrogress ? ( */}
        <ChangeLanguageForm className={css.changeLanguage} currentUser={currentUser} />
        {/* ) : null} */}

        {profileMenu}

        {signupLink}
        {loginLink}
      </div>
      {/* </div> */}
      {/* {loginLink} */}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
