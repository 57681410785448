import React from 'react';
import css from './SectionConnectingStudents.module.css';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import imageOne from './images/image1.png';
import imageTwo from './images/girls.png';
import imageThree from './images/image3.png';
import imageFour from './images/image4.png';
import imageFive from './images/recycle.png';
import NamedLink from '../NamedLink/NamedLink';
import Button from '../Button/Button';
import ExternalLink from '../ExternalLink/ExternalLink';
const EachStep = ({ image, logo, title, content }) => {
  if (image)
    return (
      <div className={css.eachStepImage}>
        {' '}
        <img src={image}></img>{' '}
      </div>
    );
  return (
    <div className={css.eachStep}>
      {logo}
      <h3 className={css.subTitle}>{title}</h3>
      <p className={css.text}>{content}</p>
    </div>
  );
};
const SectionConnectingStudents = props => {
  const { rootClassName, className } = props;
  let cookieValue;
  if (typeof window !== 'undefined') {
    cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'english';
  }
  const classes = classNames(rootClassName || css.root, className);
  const data = [
    {
      title: <FormattedMessage id="SectionConnectingStudents.SectionConnectingStudents.titleOne" />,
      image: imageOne,
      text: (
        <FormattedMessage
          id="SectionConnectingStudents.SectionConnectingStudents.textOne"
          values={{ b: msg => <b className={css.bold}>{msg}</b> }}
        />
      ),
    },

    {
      title: (
        <FormattedMessage id="SectionConnectingStudents.SectionConnectingStudents.titleThree" />
      ),
      image: imageThree,
      text: (
        <FormattedMessage
          id="SectionConnectingStudents.SectionConnectingStudents.textThree"
          values={{ b: msg => <b className={css.bold}>{msg}</b> }}
        />
      ),
    },
    {
      title: <FormattedMessage id="SectionConnectingStudents.SectionConnectingStudents.titleTwo" />,
      image: imageTwo,
      text: (
        <FormattedMessage
          id="SectionConnectingStudents.SectionConnectingStudents.textTwo"
          values={{ b: msg => <b className={css.bold}>{msg}</b> }}
        />
      ),
    },
    {
      title: (
        <FormattedMessage id="SectionConnectingStudents.SectionConnectingStudents.titleFour" />
      ),
      image: imageFour,
      text: (
        <FormattedMessage
          id="SectionConnectingStudents.SectionConnectingStudents.textFour"
          values={{
            b: msg => <b className={css.bold}>{msg}</b>,
          }}
        />
      ),
    },
    {
      title: (
        <FormattedMessage id="SectionConnectingStudents.SectionConnectingStudents.titlefive" />
      ),
      image: imageFive,
      text: (
        <FormattedMessage
          id="SectionConnectingStudents.SectionConnectingStudents.textFive"
          values={{
            b: msg => <b className={css.bold}>{msg}</b>,
          }}
        />
      ),
    },
  ];
  return (
    <div className={css.section}>
      <div className={css.title}>
        <FormattedMessage id="SectionConnectingStudents.SectionConnectingStudents.mainTitle" />
      </div>
      <div className={css.stepsContainer}>
        {data.map((item, index) => (
          <EachStep
            className={css.eachStep}
            logo={<img className={css.logo} src={item.image} />}
            title={item.title}
            content={item.text}
          />
        ))}
      </div>
      <div className={css.buttonContainer}>
        <NamedLink name="ContactUsPage">
          <Button className={css.button}>
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.ContactButtonText" />
          </Button>
        </NamedLink>

        {/* <NamedLink name="ContactUsPage">
          <Button className={css.button}>
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.ContactButtonText" />
          </Button>
        </NamedLink> */}
      </div>
    </div>
  );
};

export default SectionConnectingStudents;
