import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import img1 from './images/img1.png';
import img2 from './images/img2.png';
import { Button, NamedLink } from '../../components';
import calendarLogo from './images/Listing.png';
import goodLogo from './images/good.svg';
import happyLogo from './images/happy.svg';
import happyPng from './images/Student.png';
import planeLogo from './images/Group 1.png';

import css from './SectionTeacher.module.css';
const EachStep = ({ image, logo, title, content }) => {
  if (image)
    return (
      <div className={css.eachStepImage}>
        {' '}
        <img src={image}></img>{' '}
      </div>
    );
  return (
    <div className={css.eachStep}>
      {logo}
      <h3 className={css.subTitle}>{title}</h3>
      <p className={css.text}>{content}</p>
    </div>
  );
};
const SectionTeacher = props => {
  const { rootClassName, intl, className, isAuth } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <div className={css.border}></div>
        <FormattedMessage id="SectionTeacher.title" />
      </div>
      <div className={css.stepsContainer}>
        <EachStep
          className={css.eachStep}
          logo={<img className={css.logo} src={calendarLogo} />}
          title={intl.formatMessage({ id: 'SectionTeacher.SectionTeacher.s1T' })}
          // content={intl.formatMessage({ id: 'SectionTeacher.SectionTeacher.s1C' })}
          content={
            <FormattedMessage
              id="SectionTeacher.SectionTeacher.s1C"
              values={{
                b: chunks => <strong className={css.bold}>{chunks}</strong>,
              }}
            />
          }
        />
        <EachStep className={css.eachStep} image={img1} />

        <EachStep
          className={css.eachStep}
          logo={<img className={css.logo} src={happyPng} />}
          title={intl.formatMessage({ id: 'SectionTeacher.SectionTeacher.s3T' })}
          // content={intl.formatMessage({ id: 'SectionTeacher.SectionTeacher.s3C' })}
          content={
            <FormattedMessage
              id="SectionTeacher.SectionTeacher.s3C"
              values={{
                b: chunks => <strong className={css.bold}>{chunks}</strong>,
              }}
            />
          }
        />
        <EachStep image={img2} />

        {/* <EachStep
          className={css.eachStep}
          logo={<img className={css.logo} src={goodLogo} />}
          title={intl.formatMessage({ id: 'SectionTeacher.SectionTeacher.s5T' })}
          content={intl.formatMessage({ id: 'SectionTeacher.SectionTeacher.s5C' })}
        /> */}
        <EachStep
          className={css.eachStep}
          logo={<img className={css.logo} src={planeLogo} />}
          title={intl.formatMessage({ id: 'SectionTeacher.SectionTeacher.s6T' })}
          // content={intl.formatMessage({ id: 'SectionTeacher.SectionTeacher.s6C' })}
          content={
            <FormattedMessage
              id="SectionTeacher.SectionTeacher.s6C"
              values={{
                b: chunks => <strong className={css.bold}>{chunks}</strong>,
              }}
            />
          }
        />
      </div>
      <div className={css.buttonContainer}>
        <NamedLink
          name={isAuth ? 'NewListingPageWithCategories' : 'SignupPage'}
          params={{ category: 'category1' }}
        >
          {' '}
          <Button className={css.button}>
            <FormattedMessage id="SectionTeacher.SectionTeacher.buttonText" />
          </Button>
        </NamedLink>
      </div>
    </div>
  );
};

SectionTeacher.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionTeacher.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTeacher;
